import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { transformApiResponseSlide } from "@showhereco/slide-types";
import Slide from "SlidesRenderer/Slide";
import { getInitialSlideState } from "Store/slides";

export default function StaticRenderer() {
  const slideData = useRef(null);
  const [status, setStatus] = useState("Loading...");

  const searchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );

  const fetchData = useCallback(async () => {
    const slideId = searchParams.get("slide_id");
    const url = `${process.env.REACT_APP_API_URL}sync/slide-renderer/${slideId}`;
    try {
      const response = await fetch(
        `${url}?key=${process.env.REACT_APP_API_KEY}`
      );

      if (response.ok) {
        const responseData = await response.json();
        if (responseData) {
          slideData.current = transformApiResponseSlide({
            slide: responseData,
            mediaBaseUrl: `${process.env.REACT_APP_API_MEDIA_BASE_URL}${responseData.presentationId}/`,
          });
          setStatus("ok");
        } else {
          const message = `Unknown error: slide-renderer (${slideId})`;
          setStatus(message);
          throw new Error(message);
        }
      } else {
        console.error(response.statusText);
        const errorMessage = `${response.status} ${response.statusText}`;
        setStatus(errorMessage);
        throw new Error(errorMessage);
      }
    } catch (error) {
      const message = error?.message ?? "Unknown error";
      setStatus(message);
      const fetchDataError = new Error(message);
      fetchDataError.cause = error;
      Sentry.captureException(fetchDataError, {
        extra: {
          url,
          slideId,
        },
      });
    }
  }, [searchParams]);

  useEffect(() => {
    // check for params
    if (searchParams.size === 0) return setStatus("No url parameters found");
    // other safe to fetch data
    fetchData();
  }, [fetchData, searchParams.size]);

  if (status !== "ok")
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p style={{ fontSize: "3.5vw" }}>{status}</p>
      </div>
    );

  const tSlideState = getInitialSlideState(slideData.current);

  if (searchParams.has("item_id")) {
    tSlideState.gallerySlide = parseInt(searchParams.get("item_id"));
  }
  if (searchParams.has("imageIndex")) {
    tSlideState.imageIndex = parseInt(searchParams.get("imageIndex"));
  }
  if (searchParams.has("hotspotIndex")) {
    tSlideState.hotspotIndex = parseInt(searchParams.get("hotspotIndex"));
    tSlideState.showModal = true;
  }
  if (searchParams.has("hotspotGalleryIndex")) {
    tSlideState.hotspotGalleryItemIndex = parseInt(
      searchParams.get("hotspotGalleryIndex")
    );
    tSlideState.showModal = true;
  }
  if (
    searchParams.has("galleryIndex") &&
    searchParams.has("gallerySlideIndex")
  ) {
    tSlideState.gallery = parseInt(searchParams.get("galleryIndex"));
    tSlideState.gallerySlide = parseInt(searchParams.get("gallerySlideIndex"));
    tSlideState.showModal = true;
  }
  return (
    <Slide
      {...slideData.current}
      analyticsEventCallback={() => undefined}
      isCurrent={true}
      position={{ s: 0, x: 0, y: 0 }}
      state={tSlideState}
    />
  );
}
