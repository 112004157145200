import { createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { isOfflineFirst } from "config";
import { getStories } from "Store/stories";
import { signIn, getUser, signOut } from "./thunks";
import {
  getPersistedUser,
  setPersistedUser,
  removePersistedUser,
} from "./persistUser";

const initialState = {
  user: getPersistedUser(),
  isLoading: false,
  error: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setUser(state, action) {
      state.user = {
        ...action.payload,
        presentationIds: state.user?.presentationIds ?? [],
      };
      setPersistedUser(state.user);
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setUserPresentationIds(state, action) {
      if (state.user) {
        state.user.presentationIds = action.payload;
        setPersistedUser(state.user);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.fulfilled, (state, action) => {
        if (isOfflineFirst === false) {
          state.isLoading = false;
        }
        state.user = {
          name: `${action.payload.firstName} ${action.payload.lastName}`,
          email: action.payload.email,
          presentationIds: state.user?.presentationIds ?? [],
          id: action.payload.id,
        };

        Sentry.setUser({
          id: action.payload.id,
          email: action.payload.email,
        });
      })
      .addCase(getUser.rejected, (state, action) => {
        if (isOfflineFirst === false) {
          state.isLoading = false;
        }
        state.error = action.payload.message;
      })
      .addCase(signIn.pending, (state, action) => {
        state.error = initialState.error;
        state.isLoading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = initialState.error;
        const user = {
          name: `${action.payload.firstName} ${action.payload.lastName}`,
          email: action.payload.email,
          id: action.payload.id,
        };
        state.user = user;
        setPersistedUser(user);

        Sentry.setUser({
          id: action.payload.id,
          email: action.payload.email,
        });
      })
      .addCase(signIn.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(signOut.fulfilled, (state) => {
        handleSignOut(state);
        state.error = initialState.error;
      })
      .addCase(signOut.rejected, (state, action) => {
        handleSignOut(state);
        state.error = action.payload.message;
      })
      .addCase(getStories.fulfilled, (state, action) => {
        const ids = action.payload.map(({ id }) => id);
        if (state.user) {
          state.user.presentationIds = ids;
          setPersistedUser(state.user);
        }
      });
  },
});

function handleSignOut(state) {
  state.user = undefined;
  state.isLoading = initialState.isLoading;
  removePersistedUser();
  Sentry.setUser(null);
}

export const { setIsLoading, setUser, setError, setUserPresentationIds } =
  authSlice.actions;

export const selectUser = (state) => state.auth.user;
export const selectError = (state) => state.auth.error;
export const selectIsLoading = (state) => state.auth.isLoading;
export const selectUserPresentationIds = (state) =>
  state.auth.user?.presentationIds ?? [];

export default authSlice.reducer;
