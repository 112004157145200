import { useEffect, Suspense, lazy } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SlideContextProvider } from "@showhereco/slide-types";
import * as Sentry from "@sentry/react";
import {
  Gallery,
  ImageWithEmbeds,
  Pano360,
  TextWithBackground,
  Video,
  Wireframe,
  GalleryWithHotspots,
  Object3D,
  MultiDisplayGallery,
} from "@showhereco/slide-types";
import {
  setGallery,
  setHotspotIndex,
  setImageIndex,
  setHotspotItemIndex,
  setGallerySlide,
  setShowModal,
  setKeyValue,
  setVideo,
  setNestedVideo,
  toggleHawkeye,
  setPano360ControlsEnabled,
  set3DControlsEnabled,
  setMultiDisplayGallerySlide,
} from "Store/slides";
import { goTo } from "Store/structure";
import { setDisabled as setSwipeableDisabled } from "Store/swipeable";
import {
  selectScreenshareActive,
  selectScreenshareRoom,
} from "Store/syncRemote";
import { socket as localSocket } from "Store/syncLocal";
import { socket as remoteSocket } from "Store/syncRemote";
import { SpeakerNotes, useRoomControl } from "@showhereco/slide-ui";
import { useAnalytics } from "../App";

import {
  hasUiControl,
  roomControlConfig,
  role,
  isMarketingSuiteViewer,
} from "config";

const ListGallery = lazy(() => import("../SlideTypes/ListGallery"));
const GroupedGalleries = lazy(() => import("../SlideTypes/GroupedGalleries"));
const SpecGrid = lazy(() => import("../SlideTypes/SpecGrid"));
const BISHOPSGATE8_FLOORPLANS = lazy(() =>
  import("../SlideTypes/_8Bishopsgate/FloorPlans")
);
const BISHOPSGATE8_ZOOMABLEGALLERY = lazy(() =>
  import("../SlideTypes/_8Bishopsgate/ZoomableGallery/ZoomableGallery")
);
const BISHOPSGATE8_VIDEOBACKGROUND = lazy(() =>
  import("../SlideTypes/_8Bishopsgate/VideoBackground/VideoBackground")
);
const BISHOPSGATE8_SPECGRID = lazy(() =>
  import("../SlideTypes/_8Bishopsgate/SpecGrid/SpecGrid")
);
const EARNSHAW_PLANS = lazy(() => import("../SlideTypes/_TheEarnshaw/Plans"));
const EARNSHAW_SCHEDULE = lazy(() =>
  import("../SlideTypes/_TheEarnshaw/Schedule")
);
const WHITECITYPLACE_ACCORDION = lazy(() =>
  import("../SlideTypes/_WhiteCityPlace/Accordion")
);
const WHITECITYPLACE_AERIAL = lazy(() =>
  import("../SlideTypes/_WhiteCityPlace/Aerial")
);
const WHITECITYPLACE_FLOORPLANS = lazy(() =>
  import("../SlideTypes/_WhiteCityPlace/FloorPlans")
);
const WHITECITYPLACE_SCHEDULE = lazy(() =>
  import("../SlideTypes/_WhiteCityPlace/Schedule")
);
const WHITECITYPLACE_SITEPLAN = lazy(() =>
  import("../SlideTypes/_WhiteCityPlace/SitePlan")
);
const WOODCRESCENT_FLOORPLANS = lazy(() =>
  import("../SlideTypes/_WoodCrescent/FloorPlans")
);
const WOODCRESCENT_SCHEDULE = lazy(() =>
  import("../SlideTypes/_WoodCrescent/Schedule")
);
const ACRE_TITLESLIDE = lazy(() =>
  import("../SlideTypes/_Acre/TitleSlide/TitleSlide")
);
const ACRE_INTROSLIDE = lazy(() =>
  import("../SlideTypes/_Acre/IntroSlide/IntroSlide")
);
const ACRE_SCHEDULE = lazy(() =>
  import("../SlideTypes/_Acre/Schedule/Schedule")
);
const ACRE_FLOORPLANS = lazy(() =>
  import("../SlideTypes/_Acre/FloorPlan/FloorPlan")
);
const ACRE_SPACEPLANS = lazy(() =>
  import("../SlideTypes/_Acre/SpacePlans/SpacePlans")
);
const ACRE_KEYBENEFITS = lazy(() =>
  import("../SlideTypes/_Acre/KeyBenefits/KeyBenefits")
);
const ACRE_TITLESLIDEGALLERY = lazy(() =>
  import("../SlideTypes/_Acre/TitleSlideGallery/TitleSlideGallery")
);
const ACRE_SPECGRIDFOUR = lazy(() =>
  import("../SlideTypes/_Acre/SpecGridFour/SpecGridFour")
);

const Slide = (props) => {
  const dispatch = useDispatch();

  const swipeableDisabled = useSelector((state) => state.swipeable.disabled);
  const screenshareActive = useSelector(selectScreenshareActive);
  const screenshareRoom = useSelector(selectScreenshareRoom);
  const handleAnalyticsEvent = useAnalytics();
  const { sendCommand } = useRoomControl({ roomControlConfig, role });

  const renderSlide = (slide) => {
    const sharedProps = {
      analyticsEventCallback:
        props.analyticsEventCallback || handleAnalyticsEvent,
      position: slide.position,
      isCurrent: slide.isCurrent,
      typography: slide.typography,
      slideId: slide.slideId,
      slideType: slide.slideType,
      sendRoomControlCommand: sendCommand,
      screenshareActive,
      screenshareRoom,
      role,
      hasUiControl,
      roomControlConfig,
    };

    const pano360Props = {
      setSwipeableDisabled: (disabled) =>
        void dispatch(setSwipeableDisabled(disabled)),
      localSocket,
      remoteSocket,
    };
    const object3DProps = {
      setSwipeableDisabled: (disabled) =>
        void dispatch(setSwipeableDisabled(disabled)),
      localSocket,
      remoteSocket,
    };
    const handleSetPanoControlsEnabled = (enabled) =>
      void dispatch(
        setPano360ControlsEnabled({ position: slide.position, enabled })
      );
    const handleSet3DControlsEnabled = (enabled) =>
      void dispatch(
        set3DControlsEnabled({ position: slide.position, enabled })
      );
    if (slide.type === "Pano360") {
      pano360Props.controlsEnabled = slide.state.controlsEnabled;
      pano360Props.setControlsEnabled = handleSetPanoControlsEnabled;
    } else if (slide.state.pano360) {
      pano360Props.panoControlsEnabled = slide.state.pano360.controlsEnabled;
      pano360Props.setPanoControlsEnabled = handleSetPanoControlsEnabled;
    }

    if (slide.type === "Object3D") {
      object3DProps.controlsEnabled = slide.state.controlsEnabled;
      object3DProps.setControlsEnabled = handleSet3DControlsEnabled;
    }

    const commonProps = { ...sharedProps, ...pano360Props, ...object3DProps };

    switch (slide.type) {
      case "Gallery":
        return (
          <Suspense fallback={null}>
            <Gallery
              {...commonProps}
              media={slide.media}
              pagerFormat={slide.pagerFormat}
              gallerySlide={slide.state.gallerySlide}
              setGallerySlide={(index) =>
                dispatch(
                  setGallerySlide({
                    position: slide.position,
                    gallerySlide: index,
                  })
                )
              }
              overviewGrid={slide.overviewGrid}
              hawkeye={slide.state.hawkeye}
              toggleHawkeye={() => {
                dispatch(toggleHawkeye(slide.position));
              }}
              swipeableDisabled={swipeableDisabled}
              setSwipeableDisabled={(disabled) => {
                dispatch(setSwipeableDisabled(disabled));
              }}
              videoShouldPlay={slide.state.video?.shouldPlay}
              setVideoShouldPlay={(shouldPlay) => {
                dispatch(
                  setNestedVideo({
                    position: slide.position,
                    shouldPlay,
                  })
                );
              }}
              videoSelectedTimecode={slide.state.video?.selectedTimecode}
              setVideoSelectedTimecode={(selectedTimecode) => {
                dispatch(
                  setNestedVideo({
                    position: slide.position,
                    selectedTimecode,
                  })
                );
              }}
            />
          </Suspense>
        );
      case "ListGallery":
        return (
          <Suspense fallback={null}>
            <ListGallery
              {...commonProps}
              typographyVariant={slide.typographyVariant}
              sections={slide.sections}
              media={slide.media}
              gallerySlide={slide.state.gallerySlide}
              setGallerySlide={(index) =>
                dispatch(
                  setGallerySlide({
                    position: slide.position,
                    gallerySlide: index,
                  })
                )
              }
              showModal={slide.state.showModal}
              setShowModal={(showModal) =>
                dispatch(
                  setShowModal({
                    position: slide.position,
                    showModal,
                  })
                )
              }
            />
          </Suspense>
        );
      case "Object3D":
        return <Object3D {...commonProps} model={slide.model} />;

      case "TextWithBackground":
        return (
          <Suspense fallback={null}>
            <TextWithBackground
              {...commonProps}
              backgroundMedia={slide.media}
              text={slide.text}
              typographyVariant={slide.typographyVariant}
              textAlignment={slide.textAlignment}
              hawkeye={slide.state.hawkeye}
              toggleHawkeye={() => {
                dispatch(toggleHawkeye(slide.position));
              }}
            />
          </Suspense>
        );

      case "GroupedGalleries":
        return (
          <Suspense fallback={null}>
            <GroupedGalleries
              {...commonProps}
              galleries={slide.galleries}
              activeGallery={slide.state.gallery}
              gallerySlide={slide.state.gallerySlide}
              setGallery={(index) => {
                dispatch(
                  setGallery({
                    position: slide.position,
                    gallery: index,
                  })
                );
              }}
              setGallerySlide={(index) => {
                dispatch(
                  setGallerySlide({
                    position: slide.position,
                    gallerySlide: index,
                  })
                );
              }}
              showModal={slide.state.showModal}
              setShowModal={(showModal) =>
                dispatch(
                  setShowModal({
                    position: slide.position,
                    showModal,
                  })
                )
              }
            />
          </Suspense>
        );

      case "Video":
        return (
          <Suspense fallback={null}>
            <Video
              {...commonProps}
              autoPlay={slide.autoPlay}
              chapters={slide.chapters}
              fit={slide.fit}
              poster={slide.media.poster}
              source={slide.media.source}
              sourceIpad={slide.media.sourceIpad}
              controlsFormat={slide.controlsFormat}
              loop={slide.loop}
              shouldPlay={slide.state.shouldPlay}
              setShouldPlay={(shouldPlay) => {
                dispatch(
                  setVideo({
                    position: slide.position,
                    shouldPlay,
                  })
                );
              }}
              selectedTimecode={slide.state.selectedTimecode}
              setSelectedTimecode={(selectedTimecode) => {
                dispatch(
                  setVideo({
                    position: slide.position,
                    selectedTimecode,
                  })
                );
              }}
            />
          </Suspense>
        );

      case "Pano360":
        return (
          <Suspense fallback={null}>
            <Pano360
              {...commonProps}
              media={slide.media}
              pagerFormat={slide.pagerFormat}
              gallerySlide={slide.state.gallerySlide}
              setGallerySlide={(index) => {
                dispatch(
                  setGallerySlide({
                    position: slide.position,
                    gallerySlide: index,
                  })
                );
              }}
            />
          </Suspense>
        );
      case "GalleryWithHotspots":
        const preparedGalleryWithHotspots = (
          <Suspense fallback={null}>
            <GalleryWithHotspots
              {...commonProps}
              images={slide.images}
              imageIndex={slide.state.imageIndex}
              setImageIndex={(index) => {
                dispatch(
                  setImageIndex({
                    position: slide.position,
                    index,
                  })
                );
              }}
              hotspotIndex={slide.state.hotspotIndex}
              overviewGrid={slide.overviewGrid}
              setHotspotIndex={(index) => {
                dispatch(
                  setHotspotIndex({
                    position: slide.position,
                    index,
                  })
                );
              }}
              hotspotItemIndex={slide.state.hotspotGalleryItemIndex}
              setHotspotItemIndex={(index) => {
                dispatch(
                  setHotspotItemIndex({
                    position: slide.position,
                    index,
                  })
                );
              }}
              showModal={slide.state.showModal}
              pagerFormat={slide.pagerFormat.value}
              setShowModal={(showModal) =>
                dispatch(
                  setShowModal({
                    position: slide.position,
                    showModal,
                  })
                )
              }
              videoShouldPlay={slide.state.video?.shouldPlay}
              setVideoShouldPlay={(shouldPlay) => {
                dispatch(
                  setNestedVideo({
                    position: slide.position,
                    shouldPlay,
                  })
                );
              }}
              videoSelectedTimecode={slide.state.video?.selectedTimecode}
              setVideoSelectedTimecode={(selectedTimecode) => {
                dispatch(
                  setNestedVideo({
                    position: slide.position,
                    selectedTimecode,
                  })
                );
              }}
            />
          </Suspense>
        );

        return preparedGalleryWithHotspots;

      case "ImageWithEmbeds":
        const preparedImageWithEmbeds = (
          <Suspense fallback={null}>
            <ImageWithEmbeds
              {...commonProps}
              image={slide.image}
              showModal={slide.state.showModal}
              setShowModal={(showModal) =>
                dispatch(
                  setShowModal({
                    position: slide.position,
                    showModal,
                  })
                )
              }
              activeGallery={slide.state.gallery}
              setGallery={(index) =>
                dispatch(
                  setGallery({
                    position: slide.position,
                    gallery: index,
                  })
                )
              }
              gallerySlide={slide.state.gallerySlide}
              setGallerySlide={(index) => {
                dispatch(
                  setGallerySlide({
                    position: slide.position,
                    gallerySlide: index,
                  })
                );
              }}
              videoShouldPlay={slide.state.video?.shouldPlay}
              setVideoShouldPlay={(shouldPlay) => {
                dispatch(
                  setNestedVideo({
                    position: slide.position,
                    shouldPlay,
                  })
                );
              }}
              videoSelectedTimecode={slide.state.video?.selectedTimecode}
              setVideoSelectedTimecode={(selectedTimecode) => {
                dispatch(
                  setNestedVideo({
                    position: slide.position,
                    selectedTimecode,
                  })
                );
              }}
              hasThumbnailStrip={slide.hasThumbnailStrip}
            />
          </Suspense>
        );

        const legacyNamespaces = [
          "1WoodCrescent",
          "8Bishopsgate",
          "BrentCrossTown",
          "TheAcre",
          "TheEarnshaw",
          "WhiteCity",
          "WhiteCityPlace",
        ];
        if (legacyNamespaces.includes(process.env.REACT_APP_NAMESPACE)) {
          return preparedImageWithEmbeds;
        } else {
          const widescreenContainerStyles = {
            position: "absolute",
            width: "100%",
            height: "auto",
            aspectRatio: "16/9",
            overflow: "none",
            top: "50%",
            transform: "translateY(-50%)",
          };
          return (
            <div style={widescreenContainerStyles}>
              {preparedImageWithEmbeds}
            </div>
          );
        }

      case "SpecGrid":
        return (
          <Suspense fallback={null}>
            <SpecGrid
              {...commonProps}
              active={slide.state.active}
              tiles={slide.tiles}
              typographyVariant={slide.typographyVariant}
              setActive={(index) => {
                dispatch(
                  setKeyValue({
                    position: slide.position,
                    key: "active",
                    value: index,
                  })
                );
              }}
            />
          </Suspense>
        );

      case "Wireframe":
        return (
          <Suspense fallback={null}>
            <Wireframe
              {...commonProps}
              title={slide.title}
              description={slide.description}
            />
          </Suspense>
        );

      case "MultiDisplayGallery":
        let gallerySlide = slide.state.gallerySlide;

        if (isMarketingSuiteViewer) {
          const displayIndex = localStorage.getItem(
            "showhere-MultiDisplayGallery_DisplayIndex"
          );
          if (displayIndex !== null) {
            gallerySlide = parseInt(displayIndex, 10);
          }
        }

        return (
          <Suspense fallback={null}>
            <MultiDisplayGallery
              {...commonProps}
              displays={slide.displays}
              gallerySlide={gallerySlide}
              setGallerySlide={(index) =>
                dispatch(
                  setMultiDisplayGallerySlide({
                    position: slide.position,
                    gallerySlide: index,
                  })
                )
              }
              swipeableDisabled={swipeableDisabled}
              setSwipeableDisabled={(disabled) => {
                dispatch(setSwipeableDisabled(disabled));
              }}
              videoShouldPlay={slide.state.video?.shouldPlay}
              setVideoShouldPlay={(shouldPlay) => {
                dispatch(
                  setNestedVideo({
                    position: slide.position,
                    shouldPlay,
                  })
                );
              }}
              videoSelectedTimecode={slide.state.video?.selectedTimecode}
              setVideoSelectedTimecode={(selectedTimecode) => {
                dispatch(
                  setNestedVideo({
                    position: slide.position,
                    selectedTimecode,
                  })
                );
              }}
            />
          </Suspense>
        );

      case "BISHOPSGATE8_FLOORPLANS":
        return (
          <Suspense fallback={null}>
            <BISHOPSGATE8_FLOORPLANS
              {...commonProps}
              selectedLevelIndex={slide.state.selectedLevelIndex}
              setSelectedLevelIndex={(selectedLevelIndex) => {
                dispatch(
                  setKeyValue({
                    position: slide.position,
                    key: "selectedLevelIndex",
                    value: selectedLevelIndex,
                  })
                );
              }}
              showSpaceplans={slide.state.showSpaceplans}
              setShowSpaceplans={(showSpaceplans) => {
                dispatch(
                  setKeyValue({
                    position: slide.position,
                    key: "showSpaceplans",
                    value: showSpaceplans,
                  })
                );
              }}
              activeGallery={slide.state.gallery}
              transform={slide.state.transform}
              setGallery={(index) =>
                dispatch(
                  setGallery({
                    position: slide.position,
                    gallery: index,
                  })
                )
              }
              gallerySlide={slide.state.gallerySlide}
              setGallerySlide={(index) => {
                dispatch(
                  setGallerySlide({
                    position: slide.position,
                    gallerySlide: index,
                  })
                );
              }}
              showModal={slide.state.showModal}
              setShowModal={(showModal) =>
                dispatch(
                  setShowModal({
                    position: slide.position,
                    showModal,
                  })
                )
              }
            />
          </Suspense>
        );

      case "BISHOPSGATE8_SPECGRID":
        return (
          <Suspense fallback={null}>
            <BISHOPSGATE8_SPECGRID
              {...commonProps}
              active={slide.state.active}
              setActive={(index) => {
                dispatch(
                  setKeyValue({
                    position: slide.position,
                    key: "active",
                    value: index,
                  })
                );
              }}
            />
          </Suspense>
        );

      case "BISHOPSGATE8_ZOOMABLEGALLERY":
        return (
          <Suspense fallback={null}>
            <BISHOPSGATE8_ZOOMABLEGALLERY
              {...commonProps}
              areas={slide.areas}
              backgroundSource={slide.source}
              backgroundWideSource={slide.sourceWidescreen}
              active={slide.state.active}
              setActive={(index) => {
                dispatch(
                  setKeyValue({
                    position: slide.position,
                    key: "active",
                    value: slide.state.active === index ? null : index,
                  })
                );
              }}
            />
          </Suspense>
        );

      case "BISHOPSGATE8_VIDEOBACKGROUND":
        return (
          <Suspense fallback={null}>
            <BISHOPSGATE8_VIDEOBACKGROUND
              {...commonProps}
              media={slide.media}
              withFloorGuide={slide.withFloorGuide}
              floorGuideSource={slide.floorGuideSource}
              text={slide.text}
              autoHeight={slide.autoHeight}
              sizeOverride={slide.sizeOverride}
              loop={slide.loop}
              shouldPlay={slide.state.shouldPlay}
              setShouldPlay={(shouldPlay) => {
                dispatch(
                  setVideo({
                    position: slide.position,
                    shouldPlay,
                  })
                );
              }}
            />
          </Suspense>
        );

      case "WHITECITYPLACE_ACCORDION":
        return (
          <Suspense fallback={null}>
            <WHITECITYPLACE_ACCORDION
              {...commonProps}
              headline={slide.headline}
              items={slide.items}
              active={slide.state.active}
              setActive={(index) => {
                dispatch(
                  setKeyValue({
                    position: slide.position,
                    key: "active",
                    value: index,
                  })
                );
              }}
            />
          </Suspense>
        );

      case "WHITECITYPLACE_AERIAL":
        return (
          <Suspense fallback={null}>
            <WHITECITYPLACE_AERIAL
              {...commonProps}
              galleries={slide.galleries}
              activeGallery={slide.state.gallery}
              gallerySlide={slide.state.gallerySlide}
              setGallery={(index) =>
                dispatch(
                  setGallery({
                    position: slide.position,
                    gallery: index,
                  })
                )
              }
              setGallerySlide={(index) => {
                dispatch(
                  setGallerySlide({
                    position: slide.position,
                    gallerySlide: index,
                  })
                );
              }}
              showModal={slide.state.showModal}
              setShowModal={(showModal) =>
                dispatch(
                  setShowModal({
                    position: slide.position,
                    showModal,
                  })
                )
              }
            />
          </Suspense>
        );

      case "WHITECITYPLACE_FLOORPLANS":
        return (
          <Suspense fallback={null}>
            <WHITECITYPLACE_FLOORPLANS
              {...commonProps}
              buildingKey={slide.buildingKey}
              hawkeye={slide.hawkeye}
              transform={slide.state.transform}
              selectedLevelIndex={slide.state.selectedLevelIndex}
              setSelectedLevelIndex={(selectedLevelIndex) => {
                dispatch(
                  setKeyValue({
                    position: slide.position,
                    key: "selectedLevelIndex",
                    value: selectedLevelIndex,
                  })
                );
              }}
              showSpaceplans={slide.state.showSpaceplans}
              setShowSpaceplans={(showSpaceplans) => {
                dispatch(
                  setKeyValue({
                    position: slide.position,
                    key: "showSpaceplans",
                    value: showSpaceplans,
                  })
                );
              }}
              activeGallery={slide.state.gallery}
              setGallery={(index) =>
                dispatch(
                  setGallery({
                    position: slide.position,
                    gallery: index,
                  })
                )
              }
              gallerySlide={slide.state.gallerySlide}
              setGallerySlide={(index) => {
                dispatch(
                  setGallerySlide({
                    position: slide.position,
                    gallerySlide: index,
                  })
                );
              }}
              showModal={slide.state.showModal}
              setShowModal={(showModal) =>
                dispatch(
                  setShowModal({
                    position: slide.position,
                    showModal,
                  })
                )
              }
            />
          </Suspense>
        );
      case "WOODCRESCENT_FLOORPLANS":
        return (
          <Suspense fallback={null}>
            <WOODCRESCENT_FLOORPLANS
              {...commonProps}
              buildingKey={slide.buildingKey}
              hawkeye={slide.hawkeye}
              transform={slide.state.transform}
              selectedLevelIndex={slide.state.selectedLevelIndex}
              setSelectedLevelIndex={(selectedLevelIndex) => {
                dispatch(
                  setKeyValue({
                    position: slide.position,
                    key: "selectedLevelIndex",
                    value: selectedLevelIndex,
                  })
                );
              }}
              showSpaceplans={slide.state.showSpaceplans}
              setShowSpaceplans={(showSpaceplans) => {
                dispatch(
                  setKeyValue({
                    position: slide.position,
                    key: "showSpaceplans",
                    value: showSpaceplans,
                  })
                );
              }}
              activeGallery={slide.state.gallery}
              setGallery={(index) =>
                dispatch(
                  setGallery({
                    position: slide.position,
                    gallery: index,
                  })
                )
              }
              gallerySlide={slide.state.gallerySlide}
              setGallerySlide={(index) => {
                dispatch(
                  setGallerySlide({
                    position: slide.position,
                    gallerySlide: index,
                  })
                );
              }}
              showModal={slide.state.showModal}
              setShowModal={(showModal) =>
                dispatch(
                  setShowModal({
                    position: slide.position,
                    showModal,
                  })
                )
              }
            />
          </Suspense>
        );

      case "EARNSHAW_PLANS":
        return (
          <Suspense fallback={null}>
            <EARNSHAW_PLANS
              {...commonProps}
              selectedLevelIndex={slide.state.selectedLevelIndex}
              setSelectedLevelIndex={(selectedLevelIndex) => {
                dispatch(
                  setKeyValue({
                    position: slide.position,
                    key: "selectedLevelIndex",
                    value: selectedLevelIndex,
                  })
                );
              }}
              showSpaceplans={slide.state.showSpaceplans}
              setShowSpaceplans={(showSpaceplans) => {
                dispatch(
                  setKeyValue({
                    position: slide.position,
                    key: "showSpaceplans",
                    value: showSpaceplans,
                  })
                );
              }}
              activeGallery={slide.state.gallery}
              setGallery={(index) =>
                dispatch(
                  setGallery({
                    position: slide.position,
                    gallery: index,
                  })
                )
              }
              gallerySlide={slide.state.gallerySlide}
              setGallerySlide={(index) => {
                dispatch(
                  setGallerySlide({
                    position: slide.position,
                    gallerySlide: index,
                  })
                );
              }}
              showModal={slide.state.showModal}
              setShowModal={(showModal) =>
                dispatch(
                  setShowModal({
                    position: slide.position,
                    showModal,
                  })
                )
              }
            />
          </Suspense>
        );

      case "WHITECITYPLACE_SCHEDULE":
        return (
          <Suspense fallback={null}>
            <WHITECITYPLACE_SCHEDULE
              {...commonProps}
              image={slide.image}
              buildingKey={slide.buildingKey}
            />
          </Suspense>
        );

      case "WHITECITYPLACE_SITEPLAN":
        return (
          <Suspense fallback={null}>
            <WHITECITYPLACE_SITEPLAN
              {...commonProps}
              active={slide.state.active}
              setActive={(index) => {
                dispatch(
                  setKeyValue({
                    position: slide.position,
                    key: "active",
                    value: index,
                  })
                );
              }}
              goTo={(columnIndex) => {
                dispatch(
                  goTo({
                    s: slide.position.s,
                    x: columnIndex,
                    y: 0,
                  })
                );
              }}
            />
          </Suspense>
        );
      case "WOODCRESCENT_SCHEDULE":
        return (
          <Suspense fallback={null}>
            <WOODCRESCENT_SCHEDULE
              {...commonProps}
              image={slide.image}
              buildingKey={slide.buildingKey}
            />
          </Suspense>
        );

      case "EARNSHAW_SCHEDULE":
        return (
          <Suspense fallback={null}>
            <EARNSHAW_SCHEDULE
              {...commonProps}
              active={slide.state.active}
              setActive={(index) => {
                dispatch(
                  setKeyValue({
                    position: slide.position,
                    key: "active",
                    value: index,
                  })
                );
              }}
              goToPlan={(floorIndex) => {
                const y = slide.position.y + 1;
                const position = { ...slide.position, y };
                dispatch(
                  setKeyValue({
                    position,
                    key: "selectedLevelIndex",
                    value: floorIndex,
                  })
                );
                dispatch(goTo(position));
              }}
            />
          </Suspense>
        );

      case "ACRE_TITLESLIDE":
        return (
          <Suspense fallback={null}>
            <ACRE_TITLESLIDE
              {...commonProps}
              media={slide.media}
              textAlign={slide.textAlign}
              imgAlign={slide.imgAlign}
              fillImage={slide.fillImage}
              frameSpeed={slide.frameSpeed}
              text={slide.text}
              slideBackgroundColour={slide.slideBackgroundColour}
              typographyVariant={slide.typographyVariant}
              imgContainerHeight={slide.imgContainerHeight}
              imgContainerWidth={slide.imgContainerWidth}
              animationDisabled={slide.animationDisabled}
              imageNoPadding={slide.imageNoPadding}
              active={slide.state.active}
              imgBackgroundColour={slide.imgBackgroundColour}
              imgFitHeight={slide.imgFitHeight}
              setActive={(isActive) => {
                dispatch(
                  setKeyValue({
                    position: slide.position,
                    key: "active",
                    value: isActive,
                  })
                );
              }}
            />
          </Suspense>
        );

      case "ACRE_TITLESLIDEGALLERY":
        return (
          <Suspense fallback={null}>
            <ACRE_TITLESLIDEGALLERY
              {...commonProps}
              selectedItemIndex={slide.state.selectedItemIndex}
              setSelectedItemIndex={(selectedItemIndex) => {
                dispatch(
                  setKeyValue({
                    position: slide.position,
                    key: "selectedItemIndex",
                    value: selectedItemIndex,
                  })
                );
              }}
              setActive={(isActive) => {
                dispatch(
                  setKeyValue({
                    position: slide.position,
                    key: "active",
                    value: isActive,
                  })
                );
              }}
              items={slide.items}
            />
          </Suspense>
        );

      case "ACRE_INTROSLIDE":
        return (
          <Suspense fallback={null}>
            <ACRE_INTROSLIDE {...commonProps} />
          </Suspense>
        );

      case "ACRE_KEYBENEFITS":
        return (
          <Suspense fallback={null}>
            <ACRE_KEYBENEFITS
              {...commonProps}
              goToSlide={(xPos, yPos) => {
                const x = xPos;
                const y = yPos;
                const position = { ...slide.position, y, x };
                dispatch(goTo(position));
              }}
            />
          </Suspense>
        );

      case "ACRE_SCHEDULE":
        return (
          <Suspense fallback={null}>
            <ACRE_SCHEDULE
              {...commonProps}
              goToPlan={(floorIndex) => {
                const y = slide.position.y + 1;
                const position = { ...slide.position, y };
                dispatch(
                  setKeyValue({
                    position,
                    key: "selectedLevelIndex",
                    value: floorIndex,
                  })
                );
                dispatch(goTo(position));
              }}
            />
          </Suspense>
        );

      case "ACRE_FLOORPLANS":
        return (
          <Suspense fallback={null}>
            <ACRE_FLOORPLANS
              {...commonProps}
              selectedLevelIndex={slide.state.selectedLevelIndex}
              setSelectedLevelIndex={(selectedLevelIndex) => {
                dispatch(
                  setKeyValue({
                    position: slide.position,
                    key: "selectedLevelIndex",
                    value: selectedLevelIndex,
                  })
                );
              }}
              activeGallery={slide.state.gallery}
              setGallery={(index) =>
                dispatch(
                  setGallery({
                    position: slide.position,
                    gallery: index,
                  })
                )
              }
              gallerySlide={slide.state.gallerySlide}
              setGallerySlide={(index) => {
                dispatch(
                  setGallerySlide({
                    position: slide.position,
                    gallerySlide: index,
                  })
                );
              }}
              showModal={slide.state.showModal}
              setShowModal={(showModal) =>
                dispatch(
                  setShowModal({
                    position: slide.position,
                    showModal,
                  })
                )
              }
            />
          </Suspense>
        );

      case "ACRE_SPACEPLANS":
        return (
          <Suspense fallback={null}>
            <ACRE_SPACEPLANS
              {...commonProps}
              selectedLevelIndex={slide.state.selectedLevelIndex}
              setSelectedLevelIndex={(selectedLevelIndex) => {
                dispatch(
                  setKeyValue({
                    position: slide.position,
                    key: "selectedLevelIndex",
                    value: selectedLevelIndex,
                  })
                );
              }}
              activeGallery={slide.state.gallery}
              setGallery={(index) =>
                dispatch(
                  setGallery({
                    position: slide.position,
                    gallery: index,
                  })
                )
              }
              gallerySlide={slide.state.gallerySlide}
              setGallerySlide={(index) => {
                dispatch(
                  setGallerySlide({
                    position: slide.position,
                    gallerySlide: index,
                  })
                );
              }}
              showModal={slide.state.showModal}
              setShowModal={(showModal) =>
                dispatch(
                  setShowModal({
                    position: slide.position,
                    showModal,
                  })
                )
              }
            />
          </Suspense>
        );

      case "ACRE_SPECGRIDFOUR":
        return (
          <Suspense fallback={null}>
            <ACRE_SPECGRIDFOUR
              {...commonProps}
              active={slide.state.active}
              tiles={slide.tiles}
              typographyVariant={slide.typographyVariant}
              setActive={(index) => {
                dispatch(
                  setKeyValue({
                    position: slide.position,
                    key: "active",
                    value: index,
                  })
                );
              }}
            />
          </Suspense>
        );

      case undefined: {
        console.warn("No defined slide.type", slide.type, slide.title);
        const { s, x, y } = props.position;
        return (
          <div
            key={`placeholder-${s}-${x}-${y}`}
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#333",
              fontSize: "7vh",
            }}
          >
            {slide.title}
          </div>
        );
      }

      default: {
        console.warn("Unknown slide.type", slide.type, slide.title);
        const { s, x, y } = props.position;
        return (
          <div
            key={`placeholder-${s}-${x}-${y}`}
            className="unavailable-slide-type"
          >
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                  fillRule="evenodd"
                  clipRule="evenodd"
                />
              </svg>
              <p>
                <strong>Your app needs to be updated</strong>
              </p>
              <p>
                This slide uses a type of content which is not yet available to
                you.
                <br />
                Please update your app to present this slide.
              </p>
              <p>
                <code>{slide.type}</code>
              </p>
            </div>
          </div>
        );
      }
    }
  };

  const { isCurrent, roomControlCommands = [] } = props;
  useEffect(() => {
    if (isCurrent && role === "broadcast") {
      roomControlCommands.forEach((string) => sendCommand(string));
    }
  }, [isCurrent, roomControlCommands, sendCommand]);

  const { position } = props;
  const portalIdModal = `portal_${position.s}-${position.x}-${position.y}`;

  return [
    <Sentry.ErrorBoundary key="slide-error-boundary" fallback={ErrorFallback}>
      <SlideContextProvider value={{ isCurrent: props.isCurrent }}>
        {renderSlide(props)}
      </SlideContextProvider>
    </Sentry.ErrorBoundary>,
    <div key={portalIdModal} id={portalIdModal} />,
    props.speakerNotes &&
      props.speakerNotes?.body !== "<p></p>" &&
      hasUiControl && (
        <SpeakerNotes
          key="speaker-notes"
          title={props.speakerNotes?.title}
          body={props.speakerNotes?.body}
          isCurrent={props.isCurrent}
          position={props.position}
        />
      ),
  ];
};

function ErrorFallback() {
  return (
    <div className="error-fallback">
      <div className="error-fallback__body">
        <h1>This slide isn't working right now</h1>
        <p>You can continue to present other slides</p>
      </div>
      <div className="error-fallback__footer">
        <p>Technical support has been notified</p>
      </div>
    </div>
  );
}

export default Slide;
