type Role = "broadcast" | "listen" | "static";

type Target = "local" | "remote";

type Namespace =
  | "105VS"
  | "1WoodCrescent"
  | "318OxfordStreet"
  | "8Bishopsgate"
  | "Arlington"
  | "BeaconCapital"
  | "BrentCrossTown"
  | "BritishLand"
  | "HereEast"
  | "CanadaWater"
  | "ChiswickPark"
  | "CushmanWakefield"
  | "DovetailBuilding"
  | "TheSmith"
  | "Demo"
  | "Derwent"
  | "dnco"
  | "GPE"
  | "JuddStreet"
  | "Medelan"
  | "Related"
  | "RootsInTheSky"
  | "Showhere"
  | "ShowhereSandbox"
  | "ShowhereStaging"
  | "SlidesDemo"
  | "TheAcre"
  | "TheCrownEstate"
  | "TheEarnshaw"
  | "WhiteCity"
  | "StratfordCross"
  | "WhiteCityPlace";

type AppFeatures =
  | "sync-local"
  | "sync-remote"
  | "room-control"
  | "pdf-builder";

type NamespaceConfig = {
  appName: string;
  dataProvider: "disk" | "api:key" | "api:auth";
  dataProviderScope?: {
    accountIds: number[];
    projectIds: number[];
    presentationIds: number[];
  };
  features: AppFeatures[];
  syncLocal?: {
    holdscreen: {
      media: {
        type: "Image" | "Video";
        src: string;
      };
    };
  };
  syncRemote?: {
    apiUrl: string;
    clientUrl: string;
    holdscreen: {
      media?: {
        type: "Image" | "Video";
        src: string;
      };
      theme: {
        color: string;
        backgroundColor: string;
      };
    };
  };
  pdfBuilder?: {
    url: string;
  };
  roomControl?: {
    apiUrl: string;
  };
};

const syncRemoteAPiUrl = "https://screenshare-api.showhere.co";

const config: Record<Namespace, NamespaceConfig> = {
  "8Bishopsgate": {
    appName: "8 Bishopsgate",
    dataProvider: "disk",
    features: ["sync-local"],
    roomControl: {
      apiUrl: "http://10.0.0.14:4444",
    },
  },
  Arlington: {
    appName: "Advanced Research Clusters",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [7],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncLocal: {
      holdscreen: {
        media: {
          type: "Image",
          src: "media/Arlington/hold-screen-showhere.png",
        },
      },
    },
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join-arc.showhere.co",
      holdscreen: {
        media: {
          type: "Image",
          src: "media/Arlington/hold-screen.png",
        },
        theme: {
          color: "black",
          backgroundColor: "#6d4aff",
        },
      },
    },
  },
  Medelan: {
    appName: "Medelan",
    dataProvider: "api:auth", // disk, api:key, api:auth
    dataProviderScope: {
      accountIds: [23],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncLocal: {
      holdscreen: {
        media: {
          type: "Image",
          src: "media/Medelan/hold-screen.png",
        },
      },
    },
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join-medelan.showhere.co",
      holdscreen: {
        media: {
          type: "Image",
          src: "media/Medelan/hold-screen.png",
        },
        theme: {
          color: "black",
          backgroundColor: "white",
        },
      },
    },
  },
  BrentCrossTown: {
    appName: "Brent Cross Town",
    dataProvider: "api:auth", // disk, api:key, api:auth
    dataProviderScope: {
      accountIds: [4],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncLocal: {
      holdscreen: {
        media: {
          type: "Image",
          src: "media/BrentCrossTown/hold-screen-local.png",
        },
      },
    },
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join.brentcrosstown.co.uk",
      holdscreen: {
        media: {
          type: "Video",
          src: "media/BrentCrossTown/hold-screen-remote.mp4",
        },
        theme: {
          color: "white",
          backgroundColor: "black",
        },
      },
    },

    roomControl: {
      apiUrl: "http://192.168.1.99:4444",
    },
  },
  BritishLand: {
    appName: "British Land",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [10],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncLocal: {
      holdscreen: {
        media: {
          type: "Image",
          src: "media/BritishLand/hold-screen.png",
        },
      },
    },
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join-britishland.showhere.co",
      holdscreen: {
        media: {
          type: "Image",
          src: "media/BritishLand/hold-screen.png",
        },
        theme: {
          color: "#007da4",
          backgroundColor: "white",
        },
      },
    },
  },
  TheAcre: {
    appName: "The Acre",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [14],
      projectIds: [15],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join.theacre.com",
      holdscreen: {
        media: {
          type: "Image",
          src: "media/TheAcre/hold-screen.png",
        },
        theme: {
          color: "black",
          backgroundColor: "#e7c4ad",
        },
      },
    },
  },
  SlidesDemo: {
    appName: "Showhere Demo",
    dataProvider: "disk",
    features: [],
  },
  TheEarnshaw: {
    appName: "The Earnshaw",
    dataProvider: "disk",
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join.theearnshaw.com",
      holdscreen: {
        media: {
          type: "Video",
          src: "media/TheEarnshaw/hold-screen.mp4",
        },
        theme: {
          color: "white",
          backgroundColor: "black",
        },
      },
    },
  },
  "1WoodCrescent": {
    appName: "1 Wood Crescent",
    dataProvider: "disk",
    features: ["sync-local"],
  },
  Demo: {
    appName: "Demo",
    dataProvider: "disk",
    features: [],
  },
  dnco: {
    appName: "DNCO",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [1],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join-dnco.showhere.co/",
      holdscreen: {
        theme: {
          color: "white",
          backgroundColor: "black",
        },
      },
    },
  },
  Showhere: {
    appName: "Showhere",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [3],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join-demo.showhere.co/",
      holdscreen: {
        theme: {
          color: "#0d090b",
          backgroundColor: "#0fbef4",
        },
      },
    },
  },
  TheSmith: {
    appName: "The Smith",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [25],
      projectIds: [36],
      presentationIds: [103],
    },
    features: ["sync-local", "sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join-thesmith.showhere.co/",
      holdscreen: {
        theme: {
          color: "#0d090b",
          backgroundColor: "#0fbef4",
        },
      },
    },
  },
  ShowhereSandbox: {
    appName: "Showhere Sandbox",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [1, 3],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join-sandbox.showhere.co/",
      holdscreen: {
        theme: {
          color: "#0d090b",
          backgroundColor: "#0fbef4",
        },
      },
    },
  },
  ShowhereStaging: {
    appName: "Showhere Staging",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [5],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-local", "sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join-staging.showhere.co",
      holdscreen: {
        theme: {
          color: "#0d090b",
          backgroundColor: "#0fbef4",
        },
      },
    },
  },
  WhiteCity: {
    appName: "White City",
    dataProvider: "disk",
    features: ["sync-local", "room-control"],
    roomControl: {
      apiUrl: "http://192.168.1.56:4444",
    },
    syncLocal: {
      holdscreen: {
        media: {
          type: "Image",
          src: "media/WhiteCityPlace/intro/logo-widescreen.png",
        },
      },
    },
  },
  WhiteCityPlace: {
    appName: "White City Place",
    dataProvider: "disk",
    features: [],
  },
  GPE: {
    appName: "Hanover",
    dataProvider: "api:key",
    features: [],
  },
  StratfordCross: {
    appName: "Stratford Cross",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [11],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join-stratfordcross.showhere.co",
      holdscreen: {
        theme: {
          color: "black",
          backgroundColor: "#a8de3c",
        },
      },
    },
  },
  CanadaWater: {
    appName: "Canada Water",
    dataProvider: "api:key",
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join-canadawater.showhere.co",
      holdscreen: {
        theme: {
          color: "#0d090b",
          backgroundColor: "#0fbef4",
        },
      },
    },
  },
  ChiswickPark: {
    appName: "Chiswick Park",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [24],
      projectIds: [35],
      presentationIds: [],
    },
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join-chiswickpark.showhere.co",
      holdscreen: {
        theme: {
          color: "#483C1D",
          backgroundColor: "#F9DA49",
        },
      },
    },
  },
  Related: {
    appName: "Related",
    dataProvider: "api:key",
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join-related.showhere.co",
      holdscreen: {
        theme: {
          color: "#fff",
          backgroundColor: "#143664",
        },
      },
    },
  },
  RootsInTheSky: {
    appName: "Roots in the Sky",
    dataProvider: "api:key",
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join-rootsinthesky.showhere.co",
      holdscreen: {
        theme: {
          color: "#fff",
          backgroundColor: "#333",
        },
      },
    },
  },
  "318OxfordStreet": {
    appName: "318",
    dataProvider: "api:key",
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join-318.showhere.co",
      holdscreen: {
        theme: {
          color: "#fff",
          backgroundColor: "#333",
        },
      },
    },
  },
  CushmanWakefield: {
    appName: "Cushman & Wakefield",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [18],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join-cushwake.showhere.co",
      holdscreen: {
        media: {
          type: "Image",
          src: "media/CushmanWakefield/hold-screen.png",
        },
        theme: {
          color: "black",
          backgroundColor: "white",
        },
      },
    },
  },
  HereEast: {
    appName: "Here East",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [31],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join-hereeast.showhere.co",
      holdscreen: {
        theme: {
          color: "#fff",
          backgroundColor: "#333",
        },
      },
    },
  },
  Derwent: {
    appName: "Derwent London",
    dataProvider: "api:auth",
    dataProviderScope: {
      accountIds: [19],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join-derwent.showhere.co",
      holdscreen: {
        theme: {
          color: "#fff",
          backgroundColor: "#333",
        },
      },
    },
  },
  TheCrownEstate: {
    appName: "The Crown Estate",
    dataProvider: "api:key",
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join-tce.showhere.co",
      holdscreen: {
        theme: {
          color: "#fff",
          backgroundColor: "#333",
        },
      },
    },
  },
  JuddStreet: {
    appName: "Judd Street",
    dataProvider: "api:key",
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join-juddstreet.showhere.co",
      holdscreen: {
        theme: {
          color: "#fff",
          backgroundColor: "#333",
        },
      },
    },
  },
  BeaconCapital: {
    appName: "Beacon Capital",
    dataProvider: "api:auth", // disk, api:key, api:auth
    dataProviderScope: {
      accountIds: [22],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join-beaconcapital.showhere.co",
      holdscreen: {
        theme: {
          color: "#fff",
          backgroundColor: "#333",
        },
      },
    },
  },
  "105VS": {
    appName: "105VS",
    dataProvider: "api:auth", // disk, api:key, api:auth
    dataProviderScope: {
      accountIds: [28],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join-105vs.showhere.co",
      holdscreen: {
        theme: {
          color: "#fff",
          backgroundColor: "#333",
        },
      },
    },
  },
  DovetailBuilding: {
    appName: "DovetailBuilding",
    dataProvider: "api:auth", // disk, api:key, api:auth
    dataProviderScope: {
      accountIds: [],
      projectIds: [],
      presentationIds: [],
    },
    features: ["sync-remote"],
    syncRemote: {
      apiUrl: syncRemoteAPiUrl,
      clientUrl: "https://join-dovetail-building.showhere.co",
      holdscreen: {
        theme: {
          color: "#fff",
          backgroundColor: "#333",
        },
      },
    },
  },
};

export const target: Target = process.env.REACT_APP_WS_TARGET as Target;
export const role: Role = process.env.REACT_APP_WS_ROLE as Role;
export const nsp: Namespace = process.env.REACT_APP_NAMESPACE as Namespace;
export const contentKey = process.env.REACT_APP_API_CONTENT_KEY;

const nspConfig = config[nsp] ?? {};

if (!nsp && role !== "static") {
  console.warn(
    "Missing environment variable REACT_APP_NAMESPACE",
    process.env.REACT_APP_NAMESPACE
  );
}
if (typeof config[nsp] === "undefined" && role !== "static") {
  console.warn(
    "Missing configuration for namespace",
    process.env.REACT_APP_NAMESPACE
  );
}

export default nspConfig;
export const appFeatures = nspConfig.features;
export const appName = nspConfig.appName;
export const dataProvider = nspConfig.dataProvider;
export const dataProviderScope = nspConfig.dataProviderScope;
export const syncLocalConfig = nspConfig.syncLocal;
export const syncRemoteConfig = nspConfig.syncRemote;
export const roomControlConfig = nspConfig.roomControl;
export const pdfBuilderConfig = nspConfig.pdfBuilder;

export const hasUiControl = role === "broadcast";
export const isMarketingSuiteViewer = target === "local" && role === "listen";
export const isScreenShareViewer = target === "remote" && role === "listen";
export const isWKWebView =
  !!(window as any).webkit?.messageHandlers &&
  !navigator.userAgent.includes("CriOS"); // exclude Chrome for iOS
export const isWKWebViewTrackingAuthorized = () =>
  (window as any).AppTrackingTransparencyAuthorizationStatus === "authorized";
export const isElectronPresenter =
  new URLSearchParams(window.location.search).get("mediaContext") ===
  "electronPresenter";
export const isOfflineFirst =
  isWKWebView || isElectronPresenter || isMarketingSuiteViewer;
