import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import GitInfo from "react-git-info/macro";
import App from "./App";
import { role, target } from "config";
import { maskEmailAddress } from "utilities";

const gitInfo = GitInfo();

Sentry.init({
  dsn: "https://ec1d655f6bd049fab52d38b02dd06d01@o475832.ingest.sentry.io/5514366",
  environment: process.env.NODE_ENV,
  release: `presenter@${gitInfo.commit.hash}`,
  beforeSend(event) {
    if (event.user?.email) {
      event.user.email = maskEmailAddress(event.user.email);
    }
    return event;
  },
});

Sentry.setContext("Environment variables", {
  "Client namespace": process.env.REACT_APP_NAMESPACE,
  "Sync role": role,
  "Sync target": target,
});

window.addEventListener(
  "error",
  (event) => {
    Sentry.captureException(new Error("Unhandled Browser Error"), {
      extra: {
        url: window.location.href,
        src: event?.target?.src ?? "unknown",
      },
    });
  },
  true // useCapture - necessary for resource loading errors
);

const root = createRoot(document.getElementById("root"));
root.render(<App />);
