import { createSlice } from "@reduxjs/toolkit";
import store from "Store";
import {
  addStory,
  removeStory,
  getStories,
  addSequence,
  updateSequence,
  removeSequence,
} from "Store/stories";
import {
  isOfflineFirst,
  isMarketingSuiteViewer,
  isWKWebView,
  isElectronPresenter,
  dataProvider,
} from "config";

/* 
{
  presentationId: number,
  stage: "waiting" | "started" | "downloading" | "installing" | "complete"
  progress: {
    download: number,
    install: number,
  },
  error: null | string,
  isNew: bool,
}
*/

export const STORAGE_KEY = "showhere-stories";
if (isOfflineFirst === false) {
  window.localStorage.removeItem(STORAGE_KEY);
}
export const initialStoriesOffline =
  JSON.parse(window.localStorage.getItem(STORAGE_KEY)) ?? [];

export const storiesOfflineFirstManagerSlice = createSlice({
  name: "storiesOfflineFirstManager",
  initialState: [],
  reducers: {
    add: {
      reducer: (state, action) => {
        const queuedUpdate = state.find(
          (update) => update.presentation.id === action.payload.presentation.id
        );

        // if this a new update, add it
        if (!queuedUpdate) {
          state.push(action.payload);
          return;
        }

        // if the update has started, leave it alone
        if (queuedUpdate.stage !== "waiting") {
          return;
        }

        // overwrite a waiting update with the latest incoming version
        // (this enables skipping over queued versions, e.g. from v2 to v5)
        const queuedUpdateIdx = state.findIndex(
          (update) => update.presentation.id === action.payload.presentation.id
        );
        if (queuedUpdateIdx !== -1) {
          state.splice(queuedUpdateIdx, 1);
          state.push(action.payload);
        }
      },
      prepare: (presentation) => ({
        payload: {
          presentation,
          stage: "waiting",
          totalBytesToDownload: -1,
          progress: {
            download: null,
            install: null,
          },
          error: null,
          isNew: !initialStoriesOffline.some(
            ({ id, mediaStatus }) =>
              id === presentation.id && mediaStatus === "complete"
          ),
        },
      }),
    },
    start(state, action) {
      const updateWaiting = state.find(
        (update) => update.presentation.id === action.payload
      );
      if (!updateWaiting) return;

      updateWaiting.stage = "started";

      try {
        const presentationId = updateWaiting.presentation.id;
        const { version } = updateWaiting.presentation;
        if (isWKWebView) {
          window.webkit?.messageHandlers.mediaHandler.postMessage({
            action: "getPresentationMedia",
            payload: {
              presentationId,
              version,
              apiURL: process.env.REACT_APP_API_URL,
              apiKey: process.env.REACT_APP_API_KEY,
              cdnURL: process.env.REACT_APP_API_MEDIA_BASE_URL,
            },
          });
        } else if (isElectronPresenter) {
          window.electronAPI?.getPresentationMedia({ presentationId, version });
        } else {
          throw new Error("Uncaught `start` target in offline media handler");
        }
      } catch (err) {
        console.log(err);
      }
    },
    setProgress(state, { payload }) {
      const update = state.find(
        ({ presentation }) => presentation.id === payload.presentationId
      );

      if (!update) {
        console.log("Could not call setProgress on pending update");
        console.log({ update, payload });
        return;
      }

      if (payload.type === "error") {
        update.error = payload.message;
        console.error(payload.message);
        return;
      }

      if (payload.type === "totalBytesToDownload") {
        update.totalBytesToDownload = payload.totalBytesToDownload;
        return;
      }

      if (update.stage === "started") {
        update.stage = "downloading";
      }

      // sets `progress: { download, install }` values
      update.progress[payload.type] = payload.percentage;

      // advance onto next stage when reaching 100%
      if (payload.percentage === 100) {
        if (payload.type === "download") {
          update.stage = "installing";
        } else if (payload.type === "install") {
          update.stage = "complete";
        }
      }
    },
    remove(state, action) {
      const idx = state.findIndex(
        (update) => update.presentation.id === action.payload
      );
      if (idx !== -1) state.splice(idx, 1);
    },
  },
  extraReducers: (builder) => {
    builder.addCase("stories/removeStory", (state, action) => {
      if (isOfflineFirst) {
        const payload = { presentationId: action.payload.id };
        try {
          window.webkit?.messageHandlers.mediaHandler.postMessage({
            action: "deletePresentationMedia",
            payload,
          });
        } catch (err) {
          console.log(err);
        }
      }
    });
  },
});

if (isOfflineFirst && dataProvider.startsWith("api:")) {
  // Once the Redux store has loaded, check media-integrity of initially-loaded stories.
  // If a story's associated media is incomplete for any reason, then re-queue for processing.
  const waitForStore = setInterval(() => {
    if (!store) {
      return;
    }
    clearInterval(waitForStore);
    initialStoriesOffline.forEach((story) => {
      if (story.mediaStatus === "incomplete") {
        store.dispatch(add(story));
      }
    });
  }, 100);

  // Check for presentation updates periodically
  const fiveMinsInMs = 300000;
  const thirtySecsInMs = 30000;
  const updateInterval = isMarketingSuiteViewer ? fiveMinsInMs : thirtySecsInMs;
  setInterval(() => {
    if (window.navigator.onLine === false) {
      console.log(
        "Blocked periodic presentation updates check for offline client"
      );
      return;
    }
    if (
      dataProvider === "api:auth" &&
      typeof store.getState().auth.user === "undefined" &&
      !isMarketingSuiteViewer
    ) {
      console.log(
        "Blocked periodic presentation updates check for unauthenticated client"
      );
      return;
    }
    console.log("Checking for presentation updates");
    store.dispatch(getStories());
  }, updateInterval);

  // Monitor offline-related media events (iOS + Electron)
  window.addEventListener("OfflineMediaHandler", ({ detail: event }) => {
    if (event.type === "totalBytesToDownload") {
      store.dispatch(
        setProgress({
          presentationId: event.presentationId,
          type: "totalBytesToDownload",
          totalBytesToDownload: event.totalBytesToDownload,
        })
      );
    } else if (event.type === "progress") {
      store.dispatch(
        setProgress({
          presentationId: event.presentationId,
          type: event.progressType,
          percentage: event.progressPercentage,
        })
      );
    } else if (event.type === "complete") {
      const update = store
        .getState()
        .storiesOfflineFirstManager.find(
          ({ presentation }) => presentation.id === event.presentationId
        );
      if (!update) {
        console.log(
          "Unexpected: an offline-media-handler event of type `complete` failed to match with a pending update"
        );
        return;
      }

      // add the story for use
      store.dispatch(
        addStory({
          ...update.presentation,
          mediaStatus: "complete",
          mediaCompletedAt: Date.now(),
        })
      );

      // remove the update from the queue
      store.dispatch(remove(event.presentationId));
    } else if (event.type === "error") {
      store.dispatch(
        setProgress({
          presentationId: event.presentationId,
          type: "error",
          message: event.message,
        })
      );
    } else {
      console.log("Unexpected OfflineMediaHandler event-type", event.type);
    }
  });
}

export function diffLatestPublishedAgainstOfflineCached({
  latestPublished,
  offlineCached,
  selectedSequenceId,
  dispatch,
}) {
  // delete stories which are cached offline but not returned from the API
  offlineCached.forEach((offlineStory) => {
    const isArchived =
      latestPublished.some(
        (publishedStory) => offlineStory.id === publishedStory.id
      ) === false;
    if (isArchived) {
      dispatch(removeStory(offlineStory));
    }
  });

  // see which stories are new or updates to existing,
  // and then queue the relevant action depending on context
  latestPublished.forEach((latestPublishedStory) => {
    const offlineCachedStory = offlineCached.find(
      ({ id }) => id === latestPublishedStory.id
    );

    let storyEvent = null;
    if (!offlineCachedStory && latestPublishedStory.archived === false) {
      storyEvent = "new";
    } else if (latestPublishedStory.version > offlineCachedStory.version) {
      storyEvent = "update";
    } else if (offlineCachedStory && latestPublishedStory.archived === true) {
      // this will likely never happen: the API should not return archived stories
      storyEvent = "delete";
    }

    if (storyEvent === null) {
      console.log(
        `[diffPublishedAgainstCache] "${latestPublishedStory.title}" is up to date`
      );

      // next, check for new + updated sequences
      if (latestPublishedStory.sequences.length) {
        latestPublishedStory.sequences?.forEach((latestPublishedSequence) => {
          const cachedSequence = offlineCachedStory.sequences?.find(
            ({ id }) => id === latestPublishedSequence.id
          );

          if (typeof cachedSequence === "undefined") {
            // add a new sequence
            console.log(
              `[diffPublishedAgainstCache] "${latestPublishedStory.title}" Sequence "${latestPublishedSequence.name}" will be added`
            );
            dispatch(
              addSequence({
                story: latestPublishedStory,
                sequence: latestPublishedSequence,
              })
            );
          } else if (latestPublishedSequence.version > cachedSequence.version) {
            if (selectedSequenceId === cachedSequence.id) {
              console.log(
                `[diffPublishedAgainstCache] "${latestPublishedStory.title}" Sequence "${latestPublishedSequence.name}" cannot be updated because it is being presented`
              );
              return;
            }
            console.log(
              `[diffPublishedAgainstCache] "${latestPublishedStory.title}" Sequence "${latestPublishedSequence.name}" will be updated`
            );
            dispatch(
              updateSequence({
                story: latestPublishedStory,
                sequence: latestPublishedSequence,
              })
            );
          }
        });
      }

      // finally, remove any cached sequences which
      offlineCachedStory.sequences?.forEach((cachedSequence) => {
        const publishedSequence = latestPublishedStory.sequences.find(
          ({ id }) => id === cachedSequence.id
        );

        if (typeof publishedSequence === "undefined") {
          if (selectedSequenceId === cachedSequence.id) {
            console.log(
              `[diffPublishedAgainstCache] "${latestPublishedStory.title}" Sequence "${cachedSequence.name}" cannot be removed because it is being presented`
            );
            return;
          }
          console.log(
            `[diffPublishedAgainstCache] "${latestPublishedStory.title}" Sequence "${cachedSequence.name}" will be removed`
          );
          dispatch(
            removeSequence({
              story: latestPublishedStory,
              sequence: cachedSequence,
            })
          );
        }
      });
      return;
    }

    console.log(
      `[diffPublishedAgainstCache] Event for "${latestPublishedStory.title}" is "${storyEvent}"`
    );

    if (isMarketingSuiteViewer) {
      // process changes immediately
      switch (storyEvent) {
        case "new":
        case "update":
          dispatch(addStory(latestPublishedStory));
          return;
        case "delete":
          dispatch(removeStory(latestPublishedStory));
          return;
        default:
          console.log(
            `Could not determine an updateEvent for "${latestPublishedStory.title}"`
          );
      }
    } else {
      const storyWithMediaStatus = {
        ...latestPublishedStory,
        mediaStatus: "incomplete",
      };
      switch (storyEvent) {
        case "new":
          // add immediately, to show in StoriesGrid with media progress UI
          dispatch(addStory(storyWithMediaStatus));
          // add to update queue
          dispatch(add(storyWithMediaStatus));
          return;
        case "update":
          // add to update queue
          dispatch(add(storyWithMediaStatus));
          return;
        case "delete":
          // remove immediately
          dispatch(removeStory(latestPublishedStory));
          return;
        default:
          console.log(
            `Could not determine an updateEvent for "${latestPublishedStory.title}"`
          );
      }
    }
  });
}

export const { add, start, setProgress, remove } =
  storiesOfflineFirstManagerSlice.actions;

export const selectAvailableStoryUpdates = (state) =>
  state.storiesOfflineFirstManager;

export default storiesOfflineFirstManagerSlice.reducer;
