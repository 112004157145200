import { createSlice } from "@reduxjs/toolkit";
import { initialStoriesOffline } from "Store/storiesOfflineFirstManager";
import * as Sentry from "@sentry/react";
import { target, role, nsp, contentKey } from "config";

const slide = (state, { s, sequenceId, x, y }) => {
  const source = sequenceId
    ? state.entities.find(
        ({ position: p }) =>
          p.s === s && p.sequenceId === sequenceId && p.x === x
      )
    : state.entities.find(
        ({ position: p }) => p.s === s && p.x === x && p.y === y
      );

  const slide = source?.state;

  if (slide) return slide;

  // show warning and log error if the presentation isn't added to a content key!
  if (role === "listen" && target === "remote") {
    Sentry.captureMessage(`A presentation has not been added to the APIKey`, {
      level: "warning",
      extra: {
        nsp,
        contentKey,
        s,
        sequenceId,
        x,
        y,
      },
    });
    alert(
      "This content is not available to present just yet. Please contact support."
    );
  }

  return {};
};

const slidesSlice = createSlice({
  name: "slides",
  initialState: {
    entities: getInitialStateForStories(initialStoriesOffline),
  },
  reducers: {
    replaceSlides(state, action) {
      state.entities = action.payload.entities;
    },
    reset(state) {
      state.entities.forEach((slide) => {
        slide.state = getInitialSlideState(slide);
      });
    },
    setGallery(state, action) {
      const { position, gallery } = action.payload;
      slide(state, position).gallery = gallery;
    },
    setImageIndex(state, action) {
      const { position, index } = action.payload;
      slide(state, position).imageIndex = index;
    },
    setHotspotIndex(state, action) {
      const { position, index } = action.payload;
      slide(state, position).hotspotIndex = index;
    },
    setHotspotItemIndex(state, action) {
      const { position, index } = action.payload;
      slide(state, position).hotspotGalleryItemIndex = index;
    },
    setGallerySlide(state, action) {
      const { position, gallerySlide } = action.payload;
      slide(state, position).gallerySlide = gallerySlide;
    },
    setShowModal(state, action) {
      const { position, showModal } = action.payload;
      slide(state, position).showModal = showModal;
    },
    setKeyValue(state, action) {
      const { position, key, value } = action.payload;
      slide(state, position)[key] = value;
    },
    setPanZoom(state, action) {
      const { position, transform } = action.payload;
      slide(state, position).transform = transform;
    },
    setVideo(state, action) {
      const {
        position,
        shouldPlay = null,
        selectedTimecode = null,
      } = action.payload;
      const s = slide(state, position);
      if (shouldPlay !== null) s.shouldPlay = shouldPlay;
      if (selectedTimecode !== null) s.selectedTimecode = selectedTimecode;
    },
    setNestedVideo(state, action) {
      const {
        position,
        shouldPlay = null,
        selectedTimecode = null,
      } = action.payload;
      const s = slide(state, position);
      if (s.video) {
        if (shouldPlay !== null) s.video.shouldPlay = shouldPlay;
        if (selectedTimecode !== null)
          s.video.selectedTimecode = selectedTimecode;
      }
    },
    toggleHawkeye(state, action) {
      const s = slide(state, action.payload);
      s.hawkeye.visible = !s.hawkeye.visible;
    },
    setPano360ControlsEnabled(state, action) {
      const { position, enabled } = action.payload;
      slide(state, position).pano360.controlsEnabled = enabled;
    },
    set3DControlsEnabled(state, action) {
      const { position, enabled } = action.payload;
      slide(state, position).controlsEnabled = enabled;
    },
    setMultiDisplayGallerySlide(state, action) {
      const { position, gallerySlide } = action.payload;
      slide(state, position).gallerySlide = gallerySlide;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase("stories/setStories", (state, action) => {
        state.entities = getInitialStateForStories(action.payload);
      })
      .addCase("stories/addStory", (state, action) => {
        // remove existing stale-state slides
        state.entities = state.entities.filter(
          (slide) => slide.position.s !== action.payload.id
        );
        // add new clean-state slides
        const newSlides = getInitialStateForStory(action.payload);
        state.entities.push(...newSlides);
      })
      .addCase("stories/removeStory", (state, action) => {
        state.entities = state.entities.filter(
          (slide) => slide.position.s !== action.payload.id
        );
      })
      .addCase("stories/addSequence", (state, action) => {
        const { story, sequence } = action.payload;
        // add new clean-state slides
        const newSequenceSlides = getInitialStateForSequence(story, sequence);
        state.entities.push(...newSequenceSlides);
      })
      .addCase("stories/updateSequence", (state, action) => {
        const { story, sequence } = action.payload;
        // remove existing stale-state slides
        state.entities = state.entities.filter(
          (slide) => slide.position.sequenceId !== sequence.id
        );
        // add new clean-state slides
        const newSequenceSlides = getInitialStateForSequence(story, sequence);
        state.entities.push(...newSequenceSlides);
      });
  },
});

function getInitialStateForStories(stories) {
  return stories.flatMap((story) => getInitialStateForStory(story));
}

function getInitialStateForStory(story) {
  const presentationSlides = story.columnGroups
    .flatMap(({ columns }) => columns)
    .flatMap(({ slides }, x) =>
      slides.map((slide, y) => ({
        ...slide,
        position: { s: story.id, x, y },
        state: getInitialSlideState(slide),
        typography: story.typography?.[slide.type],
      }))
    );

  const sequenceSlides =
    story.sequences?.flatMap((sequence) =>
      getInitialStateForSequence(story, sequence)
    ) ?? [];

  return [...presentationSlides, ...sequenceSlides];
}

function getInitialStateForSequence(story, sequence) {
  return sequence.slides.flatMap((slide, x) => ({
    ...slide,
    position: {
      s: story.id,
      sequenceId: sequence.id,
      x,
      y: 0,
    },
    state: getInitialSlideState(slide),
    typography: story.typography?.[slide.type],
  }));
}

export function getInitialSlideState(slide) {
  switch (slide.type) {
    case "Gallery":
      const hasPano360 = slide.media.some((media) => media.type === "Pano360");
      const hasVideo = slide.media.some((media) => media.type === "Video");
      const hasZoomable = slide.media.some((media) => media.zoomable);
      return {
        gallerySlide: 0,
        hawkeye: {
          visible: false,
          corner: "top-right",
        },
        ...(hasPano360 && {
          pano360: { controlsEnabled: false },
        }),
        ...(hasVideo && {
          video: { shouldPlay: slide.autoPlay ?? false, selectedTimecode: 0 },
        }),
        ...(hasZoomable && {
          transform: { x: 0, y: 0, scale: 1 },
        }),
      };

    case "MultiDisplayGallery":
      return {
        gallerySlide: 0,
        pano360: { controlsEnabled: false },
        video: { shouldPlay: slide.autoPlay ?? false, selectedTimecode: 0 },
      };

    case "TextWithBackground":
      return {
        hawkeye: {
          visible: false,
          corner: "top-right",
        },
      };
    case "Object3D":
      return { controlsEnabled: false };
    case "Pano360":
      return { gallerySlide: 0, controlsEnabled: false };

    case "Video":
    case "BISHOPSGATE8_VIDEOBACKGROUND":
      return {
        shouldPlay: slide.autoPlay ?? false,
        selectedTimecode: 0,
      };
    case "GalleryWithHotspots":
      const hotspots = slide.images.map((image) =>
        image.embeds
          .filter((embed) => embed.hasOwnProperty("content"))
          .flatMap((embed) => embed.content.media)
      );
      const hasVideoHotspots = hotspots.some((hotspot) =>
        hotspot.map((media) => media.type === "Video")
      );
      const hasPano360Hotspots = hotspots.some((hotspot) =>
        hotspot.map((media) => media.type === "Pano360")
      );

      return {
        imageIndex: 0,
        hotspotIndex: 0,
        hotspotGalleryItemIndex: 0,
        showModal: false,
        ...(hasVideoHotspots && {
          video: {
            shouldPlay: slide.autoPlay ?? false, // hmm, this can change per embed but value is slide-level
            selectedTimecode: 0,
          },
        }),
        ...(hasPano360Hotspots && {
          pano360: { controlsEnabled: true },
        }),
      };

    case "ImageWithEmbeds":
      const embeds = slide.image.embeds
        .filter((embed) => embed.hasOwnProperty("content")) // remove markers without content
        .flatMap((embed) => embed.content.media);
      const hasVideoEmbeds = embeds.some((media) => media.type === "Video");
      const hasPano360Embeds = embeds.some((media) => media.type === "Pano360");
      return {
        gallery: null,
        gallerySlide: 0,

        showModal: false,
        ...(hasVideoEmbeds && {
          video: {
            shouldPlay: slide.autoPlay ?? false, // hmm, this can change per embed but value is slide-level
            selectedTimecode: 0,
          },
        }),
        ...(hasPano360Embeds && {
          pano360: { controlsEnabled: true },
        }),
      };

    case "GroupedGalleries":
    case "WHITECITYPLACE_AERIAL":
      return {
        gallery: null,
        gallerySlide: 0,
        showModal: false,
      };

    case "BISHOPSGATE8_FLOORPLANS":
    case "EARNSHAW_PLANS":
    case "WHITECITYPLACE_FLOORPLANS":
      return {
        gallery: null,
        gallerySlide: 0,
        showModal: false,
        selectedLevelIndex: slide.defaultFloorIndex ?? 0,
        showSpaceplans: false,
        transform: {
          x: 0,
          y: 0,
          scale: 1,
          willChange: "auto",
        },
      };

    case "WOODCRESCENT_FLOORPLANS":
      return {
        gallery: null,
        gallerySlide: 0,
        showModal: false,
        selectedLevelIndex: slide.defaultFloorIndex ?? 0,
        showSpaceplans: false,
        transform: {
          x: 0,
          y: 0,
          scale: 1,
          willChange: "auto",
        },
      };

    case "WOODCRESCENT_SCHEDULE":
    case "EARNSHAW_SCHEDULE":
      return { active: slide.defaultFloorIndex ?? 0 };

    case "WHITECITYPLACE_ACCORDION":
    case "WHITECITYPLACE_SITEPLAN":
    case "BISHOPSGATE8_SPECGRID":
    case "BISHOPSGATE8_ZOOMABLEGALLERY":
      return { active: null };

    case "ACRE_FLOORPLANS":
    case "ACRE_SPACEPLANS":
      return {
        selectedLevelIndex: slide.defaultFloorIndex ?? 0,
      };

    case "ACRE_TITLESLIDEGALLERY":
      return {
        selectedItemIndex: slide.selectedItemIndex ?? 0,
      };

    default:
      return {};
  }
}

export const {
  replaceSlides,
  setGallery,
  setHotspotIndex,
  setHotspotItemIndex,
  setImageIndex,
  setGallerySlide,
  setShowModal,
  setKeyValue,
  setPanZoom,
  setVideo,
  setNestedVideo,
  toggleHawkeye,
  setPano360ControlsEnabled,
  set3DControlsEnabled,
  setMultiDisplayGallerySlide,
  reset,
} = slidesSlice.actions;

export const selectSlides = (state) => state.slides.entities;
export const selectSequenceSlides = (state) => {
  const { sequenceId } = state.structure;
  if (sequenceId === null) return null;
  return state.slides.entities.filter(
    (s) => s.position.sequenceId === sequenceId
  );
};
export const selectSlide = (state) => {
  const { contentType, storyId, sequenceId, stories } = state.structure;
  if (storyId === null) return null;
  const story = stories.find((s) => s.id === storyId);

  let slide = null;
  if (contentType === "Sequence" && sequenceId) {
    const { slideIndex } = story.sequences.find(({ id }) => id === sequenceId);
    slide = state.slides.entities.find(
      ({ position: p }) => p.sequenceId === sequenceId && p.x === slideIndex
    );
  } else {
    const { columns, columnIndex } = story;
    const { slides, slideIndex } = columns[columnIndex];
    if (slides.length === 0) return null;
    const { s, x, y } = slides[slideIndex].position;
    slide = state.slides.entities.find(
      ({ position: p }) => s === p.s && x === p.x && y === p.y
    );
  }
  return slide;
};

export default slidesSlice.reducer;
